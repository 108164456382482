/* CONTACT FORM */
.contact_form {
    padding-top: 10px;
    padding-left: 5%;
    width: 100%;
    /* background-color: aquamarine; */

}

.contact_form .row {
    justify-content: flex-start;
    flex-direction: column;
}


.contact_form h1 {
    color: rgb(0, 0, 0);
    font-size: 35px;
    padding: 100px 0px 30px 0px;
}

.contact_form label {
    color: rgb(0, 0, 0);
    font-size: 16px;
    display: block;
}

.contact_form input, select, textarea {
    width: 200px;
    height: 30px;
    border-radius: 3px;
    border: 1px rgba(0, 0, 0, 0.705) solid;
    margin-bottom: 15px;
    padding: 3px;

}

.dropbtn {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 250px;
    padding: 3px;
    color: rgb(0, 0, 0);
}

textarea {
    width: 300px;
    height: 150px;
    resize: none;
}

.contact_form input::placeholder,
textarea::placeholder {
    padding: 2px;
    color: rgba(0, 0, 0, 0.377);
}

.submit{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    background-color: rgb(153, 3, 3);
    font-size: 15px;
    padding: 9px 20px;
    border-radius: 3px;
    border: none;
    box-shadow: none;
    cursor: pointer;
    color: rgb(256, 256, 256);
    transition: all 0.3s ease 0s;
}

.submit input:hover {
    background-color: rgb(165, 152, 152);

}


@media only screen and (max-width:768px) {
    .row {
        display: flex;
        flex-direction: column;
    }

    /* CONTACT FORM */
    .contact_form input {
        width: 60%;
    }

    textarea {
        width: 80%;
    }

    .submit input {
        width: 100px;
    }
    
}