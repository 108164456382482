  /* NAVIGATION */
  header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 5%;
    position: fixed;
    z-index: 1;
    background-color: #fff;
    width: 100%;
  }
  
  .logo {
    width: 60px;
    margin-right: auto;
  }
  
  li,
  a,
  button {
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    text-decoration: none;
  }
  
  .nav__links {
    list-style: none;
  }
  
  .mobile__navlinks {
    display: none;
  }
  
  .nav__links li {
    display: inline-block;
    padding: 0px 10px;
  }
  
  .nav__links li a {
    transition: all 0.3s ease 0s;
  }
  
  .nav__links li a:hover {
    color: #0088a9
  }
  
  .navbar_button {
    padding: 9px 25px;
    margin-left: 20px;
    background-color: rgb(153, 3, 3);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    color: rgb(256, 256, 256);
    font-weight: bold;
  }
  
  .navbar_button:hover {
    background-color: rgb(165, 152, 152);
  }
  
  .dropdown_icon {
    display: none;
  }
  


  @media only screen and (max-width:768px) {
    .row {
        display: flex;
        flex-direction: column;
    }

    /* NAVIGATION */


    .nav__links {
        display: none;
    }

    .show {
        display: block;

    }

    .mobile__navlinks {
        position: absolute;
        width: 100%;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        margin-top: 110px;
    }

    .mobile__navlinks a {
        display: block;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(0, 0, 0, 0.97);
        font-size: 14px;
        padding: 20px 25px 20px 25px;
        margin-left: -5px;
    }

    .dropdown {
        display: initial;
        padding: 8px 0px 8px 10px;
        z-index: 2;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .tri_bars {
        width: 25px;
        height: 2px;
        margin: 4px 0px;
        background-color: #000000;
        transition: 0.4s;
    }

    .change .bar1 {
        -webkit-transform: rotate(-45deg) translate(-6px, 7px);
        transform: rotate(-45deg) translate(-6px, 7px);
        translate: rotate(-45deg) translate(-6px, 7px);

    }

    .change .bar2 { 
        opacity: 0;

    }

    .change .bar3 {
        -webkit-transform: rotate(45deg) translate(-1px, -3px);
        transform: rotate(45deg) translate(-1px, -3px);
        translate: rotate(45deg) translate(-1px, -3px);
    }

}