
.message_sent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    text-align: center;
}

#message_1{
    font-size: 30px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.507);

}

#message_2{
    font-size: 16px;
    color: rgba(0, 0, 0, 0.507);
    padding-top: 10px;
}


@media only screen and (max-width:768px) {
    .row {
        display: flex;
        flex-direction: column;
    }

    .message_sent{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height: 400px;
    }
    #message_1{
        font-size: 17px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.507);
    
    }
    
    #message_2{
        font-size: 13px;
        color: rgba(0, 0, 0, 0.507);
        padding-top: 10px;
    }
}