
/* FOOTER_NAV */
.footer_nav {
    width: 100%;
    height: auto;
    padding: 10px 10px 10px 10px;

}

.footer_nav .row {
    justify-content: center;
}

.footer_nav_items_img,
.footer_nav_items {
    width: 20%;
    height: auto;
    color: rgba(27, 26, 26, 0.946);
    padding: 10px 25px;
}

.footer_nav_items p a {
    color: rgba(0, 0, 0, 0.705);
    font-size: 16px;
    line-height: 25px;
}


@media only screen and (max-width:768px) {
    .row {
        display: flex;
        flex-direction: column;
    }

    /* FOOTER_NAV */
    .footer_nav_items_img,
    .footer_nav_items {
        width: 100%;
    }

    .footer_nav_items p a {
        font-size: 15px;
    }
}