/* PRODUCT LISTING */

.products {
    width: 100%;
}

.products .row {
    justify-content: flex-start;
}

.products_listings h1 {
    color: rgb(0, 0, 0);
    font-size: 35px;
    padding: 100px 0px 20px 50px;
}

.product_img {
    margin: 15px 0px 15px 30px;
}

.product_img img {
    width: 300px;
    height: auto;
    border-radius: 10px;

}

.product_description {
    flex-basis: 60%;
    padding: 0px 0px 0px 30px;
    align-self: center;
}

.product_description h2 {
    font-size: 23px;
    color: rgba(0, 0, 0, 0.926);
}

.product_description p {
    padding: 5px 0px 0px 0px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.611);
}

.product_description ul li {
    list-style: none;
    padding: 10px 0px 0px 0px;
    color: rgba(0, 0, 0, 0.933);

}

.product_description button {
    margin-left: 5px;
    background-color: rgba(204, 51, 51, 0.808);
    font-size: 15px;
    color: rgba(255, 255, 255, 0.933);
    padding: 5px 10px 5px 10px;

}

button:hover {
    background-color: rgb(114, 3, 3);
}

hr {
    width: 95%;
    height: 1px;
    color: rgb(0, 0, 0);
    background-color: rgb(0, 0, 0);
    margin: 0px auto;
}




@media only screen and (max-width:768px) {
    .row {
        display: flex;
        flex-direction: column;
    }

    /* PRODUCT LISTING */
    .products_listings h1 {
        color: rgb(0, 0, 0);
        font-size: 30px;
        padding: 100px 0px 5px 5%;
        margin-bottom: 10px;
    }

    .product_img {
        flex-basis: 100%;
        margin: 0px auto;
    }

    .product_description {
        flex-basis: 100%;
        align-self: auto;

    }

    .product_description h2 {
        font-size: 15px;
    }

    .product_description p {
        font-size: 12px;
    }

    .product_description ul li {
        font-size: 13px;
    }

    .product_description button {
        font-size: 13px;
    }

    button:hover {
        background-color: rgb(114, 3, 3);
    }

    hr {
        width: 90%;
        margin: 10px auto;
    }

}