
/* FOOTER */
.footer {
    width: 100%;
    height: auto;
    background-color: rgba(218, 218, 218, 0.46);
    margin: 20px 0px 0px 0px;
    padding: 10px 10px 10px 10px;
}

.footer .row {
    justify-content: center;
}

.footer_content_1 {
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    flex-basis: 80%;

}

.footer_content_1 p {
    color: rgb(70, 67, 67);
}


