@font-face {
  font-family: Motiva Sans;
  src: url(../../fonts/motiva-sans/MotivaSansRegular.woff.ttf);
}

/* base styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
  input,
  text,
  button,
  select, a{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

/* ALL ROWS (Flex-Box) */
.row {
    display: flex;
    justify-content: space-evenly;
}

body {
    font-family: "Motiva Sans", Sans-serif, -apple-system, system, sans-serif;
    font-size: 16px;
}

.container {
    max-width: 1800px;
    margin: 0px auto 0px;
}
