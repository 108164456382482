/* PRIVACY POLICY */

.privacy {
    width: 100%;
}

.privacy .row {
    justify-content: center;
}

.privacy h1 {
    color: rgb(0, 0, 0);
    font-size: 35px;
    padding: 100px 0px 20px 50px;
}

.PrivacyPolicyText {
    padding: 0px 5% 0px 5%;
    width: 90%;
}

.PrivacyPolicyText p {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.8);
    text-align: justify;
    margin: 20px 0px 20px 0px;
}

.PrivacyPolicyText em {
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
}


@media only screen and (max-width:768px) {
    .row {
        display: flex;
        flex-direction: column;
    }

}